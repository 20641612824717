import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import "./index.css";

import App from './App';
import Assedio from "./routes/Assedio";
import Confirmacao from "./routes/Confirmacao";
import Denuncia from "./routes/Denuncia";
import QRCodeComponent from "./routes/QRCodeComponent";
import Geo from "./routes/Geo";
import Teste from './routes/Teste';

// Rotas para o App principal
const appRoutes = [
  {
    path: "/assedio",
    element: <Assedio/>,
  },
  {
    path: "/confirmacao",
    element: <Confirmacao/>,
  },
  {
    path: "/",
    element: <Denuncia/>,
  },
  {
    path: "/qrcode",
    element: <QRCodeComponent/>,
  },
  {
    path: "/denuncia/cidade",
    element: <Denuncia/>,
  },
  {
    path: "/assedio/:cidade",
    element: <Assedio/>,
  },
  {
    path: "/geolocation",
    element: <Geo/>,
  },
];

const router = createBrowserRouter([
  {
    element: <App routes={appRoutes} />,
    children: [
      {
        path: "/assedio",
        element: <Assedio/>,
      },
      {
        path: "/confirmacao",
        element: <Confirmacao/>,
      },
      {
        path: "/",
        element: <Denuncia/>,
      },
      {
        path: "/qrcode",
        element: <QRCodeComponent/>,
      },
      {
        path: "/denuncia/cidade",
        element: <Denuncia/>,
      },
      {
        path: "/assedio/:cidade",
        element: <Assedio/>,
      },
      {
        path: "/geolocation",
        element: <Geo/>,
      },
    ],
  },
  {
    path: "/teste",
    element: <Teste/>,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    <RouterProvider router={router} />
  </>
);