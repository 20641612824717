import api, { apiCconet, apiHml } from "../services/api";
import { useState, useEffect, useRef } from "react";
import React from 'react';
import Checkbox from "../components/Form/Checkbox";
import ListaGm from "../components/Form/ListaGm";
import ReactFileReader from 'react-file-reader';
import InputMask from "react-input-mask";
import * as EmailValidator from "email-validator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import Loadings from "../components/Form/Loadings";
import ReCAPTCHA from "react-google-recaptcha";
import { keyRecaptcha } from "../Config/keyReCaptcha";
import { useParams } from 'react-router-dom';
import { googleMapsApi } from "../Config/config";

import "./Teste.css";

const Teste = () => {
    // Inputs 
    const [nome, setNome] = useState([]);
    const [teste1, setTeste1] = useState([]);
    const [teste2, setTeste2] = useState([]);
    const [celular, setCelular] = useState([]);
    const [email, setEmail] = useState([]);
    const [descricaoDenuncia, setDescricaoDenuncia] = useState([]);
    const [descricaoSuspeito, setDescricaoSuspeito] = useState([]);
    const [selectValueCidade, setSelectValueCidade] = useState(0);
    const [cidades, setCidades] = useState([]);
    const [sentidoOnibus, setSentidoOnibus] = useState([]);
    const [linhaOnibus, setLinhaOnibus] = useState([]);
    const [linhaOnibusId, setLinhaOnibusId] = useState([]);
    const [linhaOnibusSentidoId, setLinhaOnibusSentidoId] = useState([]);
    const [denuncianteDesembarcou, setDenuncianteDesembarcou] = useState(false);
    const [suspeitoDesembarcou, setSuspeitoDesembarcou] = useState(false);
    const [denuncianteEmbarcou, setDenuncianteEmbarcou] = useState(false);
    const [suspeitoEmbarcou, setSuspeitoEmbarcou] = useState(false);
    const [cpf, setCpf] = useState([]);
    const [exibirApenasMensagem, setMensagemConfirmacao] = useState(false);
    const [exibirMensagemManterTela, setMensagemManterTela] = useState(false);
    const [idDenunciaAssedio, setIdDenunciaAssedio] = useState("");
    const [idLocalizacaoAtual, setIdLocalizacaoAtual] = useState("");
    //Upload de imagem e foto
    const [imagem, setImagem] = useState(null);
    const videoRef = useRef();
    const canvasRef = useRef();
    const [showTakePhotoButton, setShowTakePhotoButton] = useState(false);
    const [showStartCameraButton, setShowStartCameraButton] = useState(false);
    //Validação de forms
    const [isVisibleFirstForm, setIsVisibleFirstForm] = useState(true);
    const [isVisibleSecondForm, setIsVisibleSecondForm] = useState(false);
    const [isVisibleThirdForm, setIsVisibleThirdForm] = useState(false);
    const [isVisibleFourthForm, setIsVisibleFourthForm] = useState(false);
    const [isVisibleFifthForm, setIsVisibleFifthForm] = useState(false);
    const [isVisibleSixthForm, setIsVisibleSixthForm] = useState(false);
    const [formFinish, setFormFinish] = useState(false);
    const [sucessoEnvioFinish, setSucessoEnvioFinish] = useState(false);
    //Selects  
    const [selectedFile, setSelectedFile] = useState("");
    const [selectValue, setSelectValue] = useState(0);
    const list = [
        { id: 0, name: 'Selecione um Local' },
        { id: 1, name: 'Linha de Ônibus' },
        { id: 2, name: 'Ponto de Ônibus' },
        { id: 3, name: 'Escola' },
        { id: 4, name: 'Estabelicimento Público' },
        { id: 5, name: 'Estabelicimento Privado' },
    ];
    //Validações de erros
    const [erro, setErro] = useState(false);
    const [erroFormSecond, setErroFormSecond] = useState(false);
    const [erroFormThird, setErroFormThird] = useState(false);
    const [erroFormEmail, setErroFormEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [erroFormCPF, setErroFormCpf] = useState(false);
    const [mostrarRecaptcha, setMostrarRecaptcha] = useState(true);
    const [permitirLocalizacao, setPermitirLocalizacao] = useState(false);
    // const locationIntervalRef = useRef(null);
    //location e mensagem de envio
    // const [location, setLocation] = useState(null);
    const [latitude, setLatitude] = useState(
        parseFloat(localStorage.getItem('latitude')) || null
    );
    const [longitude, setLongitude] = useState(
        parseFloat(localStorage.getItem('longitude')) || null
    );
    const [error, setError] = useState(null);
    const [sucessoEnvio, setMensagemSucessoEnvio] = useState(false);
    const [sucessoEnvioFinal, setSucessoEnvioFinal] = useState(false);
    const [msgEnvioOcorrencia, setMsgEnvioOcorrencia] = useState(false);
    const canvas = document.createElement('canvas');
    const [mudancaStatusLocation, setMudancaStatusLocation] = useState(0);
    const [locationInterval, setLocationInterval] = useState(null);
    const [selectValueCidadeViaUrl, setSelectValueCidadeViaUrl] = useState(0);
    const [validaSelecCidade, setValidaSelecCidade] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const { cidade } = useParams();

    useEffect(() => {
        if (mudancaStatusLocation === 1) {
            let sendLocationInterval = setInterval(async () => {
                getLocation();
                if (mudancaStatusLocation === 2) {
                    clearInterval(sendLocationInterval);
                }
            }, 5000);
            setLocationInterval(sendLocationInterval);
        } else if (mudancaStatusLocation === 2) {
            clearInterval(locationInterval);
        }
    }, [mudancaStatusLocation]);

    const getLocation = () => {
        if (navigator.geolocation && mudancaStatusLocation === 1) {
            navigator.geolocation.watchPosition(
                handleSuccess,
                handleError,
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 10000,
                }
            );
        } else {
            setError('Geolocalização não é suportada neste navegador.');
        }
    };

    const handleError = error => {
        setError('Erro ao obter a localização: ' + error.message);
    };

    const handleSuccess = position => {
        const currentDate = new Date().getTime();
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        setLatitude(lat);
        setLongitude(lon);

        localStorage.setItem('latitude', lat);
        localStorage.setItem('longitude', lon);

        if (!localStorage.getItem('data') || currentDate - localStorage.getItem('data') >= 5000) {
            localStorage.setItem('data', currentDate);
            sendToEndpoint(lat, lon, idLocalizacaoAtual, mudancaStatusLocation);
        }
    };

    const sendToEndpoint = (latitude, longitude, idLocalizacaoAtual, mudancaStatusLocation) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hour = String(currentDate.getHours()).padStart(2, "0");
        const minute = String(currentDate.getMinutes()).padStart(2, "0");
        const second = String(currentDate.getSeconds()).padStart(2, "0");
        const dataAtual = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

        sendLocation(dataAtual, idLocalizacaoAtual, latitude, longitude, mudancaStatusLocation);

    };

    const sendLocation = async (dataAtual, idLocalizacaoAtual, latitude, longitude, mudancaStatusLocation) => {
        try {
            if (latitude == null && longitude == null) {
                setPermitirLocalizacao(false)
            } else {
                setPermitirLocalizacao(true)
                const response = await apiHml.post("/assedio-local-historico", {
                    id_atual: idLocalizacaoAtual,
                    latitude: latitude,
                    longitude: longitude,
                    dh_localizacao: dataAtual,
                });
                if (selectValue == 1 && mudancaStatusLocation == 2) {
                    setMudancaStatusLocation(2);
                }
                console.log("RESPOSTA LOCALIZAÇÃO ENVIADA COM SUCESSO PARA API");
            }
        } catch (err) {
            console.log("ERROR API PRINCIPAL LOCALIZAÇÃO");
        }
        return false;
    };

    async function sendData() {
        setIsLoading(true);
        if (nome && celular || nome && email) {
            try {
                if (selectValue != 0) {
                    const currentDate = new Date();
                    const year = currentDate.getFullYear();
                    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                    const day = String(currentDate.getDate()).padStart(2, "0");
                    const hour = String(currentDate.getHours()).padStart(2, "0");
                    const minute = String(currentDate.getMinutes()).padStart(2, "0");
                    const second = String(currentDate.getSeconds()).padStart(2, "0");
                    const dataAtual = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

                    var lat = null;
                    var lon = null;

                    if (latitude != null) {
                        lat = latitude;
                        lon = longitude;
                    }

                    const nomeSemEspaco = nome.trim();

                    const result = await apiHml.post("/denuncias-assedios", {
                        nome_denunciante: nomeSemEspaco,
                        celular: celular,
                        email: email,
                        descricao_denuncia: descricaoDenuncia,
                        descricao_suspeito: descricaoSuspeito,
                        local_assedio_id: selectValue,
                        linha_onibus_id: linhaOnibus ? linhaOnibus : null,
                        linha_onibus_sentido_id: sentidoOnibus ? sentidoOnibus : null,
                        denunciante_desembarcou: denuncianteDesembarcou ? "S" : "N",
                        suspeito_desembarcou: suspeitoDesembarcou ? "S" : "N",
                        denunciante_embarcou: denuncianteEmbarcou ? "S" : "N",
                        suspeito_embarcou: suspeitoEmbarcou ? "S" : "N",
                        cpf: cpf,
                        objectkey: imagem,
                        cidade_id: selectValueCidade.id,
                        latitude: lat,
                        longitude: lon,
                        dataAtual: dataAtual,
                    });

                    const idDenuncia = result.data.data.id;
                    setIdDenunciaAssedio(idDenuncia);

                    if (latitude != null) {
                        const resultAssedioLocalAtual = await apiHml.post("assedio-local-atual", {
                            id_denuncia_assedio: result.data.data.id,
                            latitude: latitude,
                            longitude: longitude,
                            dh_localizacao: dataAtual
                        });

                        if (selectValue == 1) {
                            setMudancaStatusLocation(1);
                        }

                        setIdLocalizacaoAtual(resultAssedioLocalAtual.data.data.id);
                    }
                    setIsLoading(false);


                } else {
                    alert("informe o Local do Assédio");
                    setMensagemSucessoEnvio(false);
                    console.log(sucessoEnvio)
                }

            } catch (err) {
                console.log(err.message)
            } finally {

                console.log("finalizado")
            }
            return console.log("enviando dados")
        }
        return console.log("enviando dados")
    };

    async function sendPutDenuncia() {
        try {
            const result = await apiHml.put("/denuncias-assedios", {
                id: idDenunciaAssedio,
                linha_onibus_id: linhaOnibus ?? null,
                linha_onibus_sentido_id: sentidoOnibus ?? null,
                denunciante_desembarcou: denuncianteDesembarcou ? "S" : "N",
                suspeito_desembarcou: suspeitoDesembarcou ? "S" : "N",
                denunciante_embarcou: denuncianteEmbarcou ? "S" : "N",
                suspeito_embarcou: suspeitoEmbarcou ? "S" : "N",
            });

            if (selectValue == 1 && denuncianteDesembarcou || selectValue == 1 && suspeitoDesembarcou) {
                await setMudancaStatusLocation(2);
                return false;
            }

            if (selectValue == 2 && denuncianteEmbarcou == true && suspeitoEmbarcou == true) {
                if (selectValue == 2 && denuncianteDesembarcou == true || selectValue == 2 && suspeitoDesembarcou == true) {
                    await setMudancaStatusLocation(2);
                    return false;
                }
                setMudancaStatusLocation(1);
            }
        } catch (err) {
            console.log(err.message)
        } finally {
            console.log("finalizado")
        }
        return false;
    };

    const handleSelectChangeLocal = (evento) => {
        // Função de callback para tratar a alteração do select
        const novoValorSelecionado = evento.target.value;
        setSelectValue(novoValorSelecionado);

        if (novoValorSelecionado == 1 || novoValorSelecionado == 2) {
            listagemLinhaOnibus();
        }

        // Limpar os estados dos inputs quando o select for alterado
        setSentidoOnibus("");
        setLinhaOnibus("");
        setDenuncianteDesembarcou(false);
        setSuspeitoDesembarcou(false);
        setDenuncianteEmbarcou(false);
        setSuspeitoEmbarcou(false);
    };

    useEffect(() => {
        async function lista() {
            await listagemGms();
        }
        lista();
    }, []);

    async function listagemGms() {
        const response = await apiHml.get('/cidade/formulario-denuncia')
        setCidades(response.data.data.cidades);
    }

    useEffect(() => {
        permitirLocation();
    }, []);

    useEffect(() => {
        async function handleUrlCode() {

            if (selectValueCidade !== 0 && validaSelecCidade) {
                nextSecondForm();
                return true;
            }

            if (cidade !== "") {
                let cidadeId = '';

                const urlParams = new URLSearchParams(window.location.search);
                const cidadeFromUrl = urlParams.get('cidade');

                // SWITCH CASE PARA SETAR FORMULARIO CONFORME ID DA CIDADE
                switch (cidade) {
                    case 'pinhais':
                        cidadeId = 2;
                        break;

                    case 'itabirito':
                        cidadeId = 32;
                        break;

                    default:
                        cidadeId = '';
                        break;
                }

                if (cidadeFromUrl) {
                    setSelectValueCidadeViaUrl(cidadeFromUrl);
                    cidadeId = cidadeFromUrl;
                } else {
                    setSelectValueCidadeViaUrl(cidadeId);
                }

                const cidadeItem = cidades.find(item => item.id === parseInt(cidadeId));

                if (cidadeItem) {
                    setSelectValueCidade(cidadeItem);
                    setValidaSelecCidade(true);
                } else {
                    setValidaSelecCidade(false);
                }
            }
        }
        handleUrlCode();
        setLoading(false);
    }, [cidades, selectValueCidade, validaSelecCidade]);

    useEffect(() => {
        if (selectValueCidadeViaUrl > 0) {
            const botaoAvancar = document.getElementById('botao-avancar');
            if (botaoAvancar && validaSelecCidade) {
                setValidaSelecCidade(true);
                botaoAvancar.click();
            }
        }
    }, [selectValueCidadeViaUrl, validaSelecCidade]);

    const handleCampoChange = (event) => {
        const item = JSON.parse(event.target.value);

        if (item) {
            setSelectValueCidade(item);
        } else {
            setValidaSelecCidade(false);
        }
    };

    // const handleCampoChangeLocal = () => {
    //     if(selectValue != 0){
    //       getLocation();
    //     } 
    // };

    const permitirLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    // setTeste1(position.coords.latitude);
                    // setTeste2(position.coords.longitude);
                    // getCityFromCoordinates(position.coords.latitude, position.coords.longitude);
                },
                (error) => {
                    console.error('Erro ao obter a geolocalização:', error);
                },
                { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
            );
        } else {
            alert('Geolocalização não suportada neste navegador.');
        }
    };

    // Função para obter a cidade com base nas coordenadas de geolocalização
    // const getCityFromCoordinates = async (latitude, longitude) => {
    //     let apiKey = googleMapsApi.android.apiKey;
    //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    //     try {
    //         const response = await fetch(url);
    //         const data = await response.json();

    //         if (data.results && data.results.length > 0) {
    //             // A cidade normalmente está na primeira posição do array de resultados
    //             console.log(data.results[0])
    //             const city = data.results[0].address_components.find(component =>
    //                 component.types.includes('locality')
    //             );

    //             if (city) {
    //                 console.log('Cidade:', city.long_name);
    //                 return city.long_name;
    //             } else {
    //                 console.log('Cidade não encontrada.');
    //                 return null;
    //             }
    //         } else {
    //             console.log('Nenhum resultado encontrado.');
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error('Erro ao obter a cidade:', error);
    //         return null;
    //     }
    // };

    // Realiza a validação do campo
    const validarCampo = (event) => {
        event.preventDefault();
        if (selectValueCidade.id != undefined) {
            permitirLocation();
            nextSecondForm();
        } else {
            setErro(true);
            return;
        }
    };

    // Realiza a validação do campo
    const validarCamposSecond = (event) => {
        event.preventDefault();

        if (latitude == null && longitude == null) {
            setPermitirLocalizacao(true)
            permitirLocation();
            return;
        } else {
            setPermitirLocalizacao(false)
            if (erroFormCPF == true) {
                setErroFormCpf(true)
                return;
            }

            setErroFormSecond(false);
            setErroFormEmail(false);

            if ((nome[0] != " " && nome.length > 0) && celular.length > 0 && email.length > 0 && cpf.length > 0 && erroFormEmail == false) {
                nextThirdForm();
                setShowStartCameraButton(false);
                setErroFormSecond(false);
                setErroFormEmail(false);
            } else if (erroFormEmail) {
                alert("Formato do E-mail invalido!")
                setErroFormEmail(true);
            } else {
                alert("Preencha todos os Campos!")
                setErroFormSecond(true);
                return;
            }
        }
    };

    // Realiza a validação do campo
    const validarCamposThird = (event) => {
        localStorage.clear();
        if (selectValue == 1) {
            event.preventDefault();
            if (!isCaptchaValid) {
                alert('Por favor, verifique o CAPTCHA antes de enviar o formulário.');
                return;
            }

            setErroFormThird(false);
            setMensagemSucessoEnvio(false);
            setSucessoEnvioFinal(false);
            if (descricaoDenuncia.length > 0 && descricaoSuspeito.length > 0 && linhaOnibus > 0 && sentidoOnibus > 0 && selectValue > 0) {
                sendData();
                setErroFormThird(false);
                setMensagemSucessoEnvio(true);
                setMensagemConfirmacao(true);
                setMensagemManterTela(false);
                setIsVisibleFourthForm(true);
                setMostrarRecaptcha(false);
            } else {
                alert("Preencha todos os Campos!")
                setErroFormThird(true);
                return;
            }
        } else if (selectValue == 2) {
            event.preventDefault();
            setErroFormThird(false);
            setMensagemSucessoEnvio(false);
            if (descricaoDenuncia.length > 0 && descricaoSuspeito.length > 0 && selectValue > 0) {
                sendData();
                setErroFormThird(false);
                setIsVisibleFourthForm(true);
                setMensagemConfirmacao(true);
                setMsgEnvioOcorrencia(true);
                setMostrarRecaptcha(false);
            } else {
                alert("Preencha todos os Campos!")
                setErroFormThird(true);
                return;
            }
        }
    };

    const atualizarDenuncia = async (event) => {
        sendPutDenuncia();
        if (selectValue == 1) {
            await setSucessoEnvioFinal(true);
            setIsVisibleFourthForm(false);
            setMensagemSucessoEnvio(false);
            setMensagemConfirmacao(true);
            setMudancaStatusLocation(2);
        }
        if (selectValue == 2) {
            setIsVisibleFourthForm(true);
            setSucessoEnvioFinish(true);
            setMensagemConfirmacao(true);
            setFormFinish(true);
            await setSucessoEnvioFinal(false);
            if (suspeitoEmbarcou == true && denuncianteEmbarcou == false) {
                setIsVisibleFourthForm(false);
                setIsVisibleThirdForm(false);
                setIsVisibleSecondForm(false);
                setIsVisibleFirstForm(false);
                setIsVisibleFifthForm(true);
                // setMensagemManterTela(true);
            } else {
                setIsVisibleFourthForm(false);
                setIsVisibleThirdForm(false);
                setIsVisibleSecondForm(false);
                setIsVisibleFirstForm(false);
                setIsVisibleFifthForm(false);
                setIsVisibleFifthForm(true);
                // setMensagemManterTela(true);
            }

            if (suspeitoEmbarcou == false && denuncianteEmbarcou == true) {
                setMensagemManterTela(true);
            }

            if (denuncianteDesembarcou == true || suspeitoDesembarcou == true) {
                await setSucessoEnvioFinal(true);
                setFormFinish(true);
                setIsVisibleFourthForm(false);
                setIsVisibleThirdForm(false);
                setIsVisibleSecondForm(false);
                setIsVisibleFirstForm(false);
                setSucessoEnvioFinish(false);
                setMensagemConfirmacao(false);
            }
        }
    };

    const atualizarDenunciaFifth = async (event) => {
        sendPutDenuncia();
        setFormFinish(true);
        setIsVisibleFourthForm(false);
        setIsVisibleThirdForm(false);
        setIsVisibleSecondForm(false);
        setIsVisibleFirstForm(false);
        setIsVisibleFifthForm(false);
        setIsVisibleSixthForm(true);
        // setMensagemManterTela(true);
        if (denuncianteDesembarcou == true || suspeitoDesembarcou == true) {
            setFormFinish(true);
            setIsVisibleFourthForm(false);
            setIsVisibleThirdForm(false);
            setIsVisibleSecondForm(false);
            setIsVisibleFirstForm(false);
            setIsVisibleFifthForm(false);
            setIsVisibleSixthForm(false);
            setMensagemManterTela(true);
        }

        if (suspeitoEmbarcou == true && denuncianteEmbarcou == false) {
            setMensagemManterTela(true);
        } else if (suspeitoEmbarcou == false && denuncianteEmbarcou == true) {
            setMensagemManterTela(true);
        }
    };

    // Realiza a validação do campo
    const validarCamposThirdOutros = (event) => {
        event.preventDefault();
        if (!isCaptchaValid) {
            alert('Por favor, verifique o CAPTCHA antes de enviar o formulário.');
            return;
        }

        setErroFormThird(false);
        setMensagemSucessoEnvio(false);
        if (descricaoDenuncia.length > 0 && descricaoSuspeito.length > 0 && selectValue > 0) {
            sendData();
            setErroFormThird(false);
            setMensagemSucessoEnvio(true);
            setMensagemConfirmacao(true);
            setMostrarRecaptcha(false);
        } else {
            alert("Preencha todos os Campos!")
            setErroFormThird(true);
            return;
        }
    };

    // Mask para celular
    const handleTelefoneChange = (event) => {
        setCelular(event.target.value);
    };

    // Inicio validações CPF
    useEffect(() => {
        if (cpf.length === 11) {
            // Executa a validação do CPF quando o usuário terminar de digitar
            if (validarCPF(cpf)) {
                setErroFormCpf(false);
            } else {
                setErroFormCpf(true);
            }
        }
    }, [cpf]);

    function validarCPF(cpf) {
        // Remove caracteres especiais e de formatação
        cpf = cpf.replace(/\D/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) {
            return false;
        }

        // Verifica se todos os dígitos são iguais
        if (/^(\d)\1{10}$/.test(cpf)) {
            return false;
        }

        // Calcula o primeiro dígito verificador
        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let digit = 11 - (sum % 11);
        if (digit >= 10) {
            digit = 0;
        }
        if (parseInt(cpf.charAt(9)) !== digit) {
            return false;
        }

        // Calcula o segundo dígito verificador
        sum = 0;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        digit = 11 - (sum % 11);
        if (digit >= 10) {
            digit = 0;
        }
        if (parseInt(cpf.charAt(10)) !== digit) {
            return false;
        }

        return true;
    }

    function handleCpfChange(event) {
        const value = event.target.value;
        const numericValue = value.replace(/\D/g, ''); // Remove os caracteres não numéricos
        setCpf(numericValue);
    }
    // fim alidações CPF

    // Mask para Email
    const handleChangeEmail = (evento) => {
        setEmail(evento.target.value);
    };

    const validarEmail = () => {
        if (EmailValidator.validate(email)) {
            // Se o e-mail for válido
            setErroFormEmail(false);
        } else {
            setErroFormEmail(true);
        }
    };

    function nextSecondForm() {
        setErroFormCpf(false);
        setIsVisibleFirstForm(false);
        setIsVisibleThirdForm(false);
        setIsVisibleSecondForm(true);
    }

    function nextThirdForm() {
        setIsVisibleFirstForm(false);
        setIsVisibleSecondForm(false);
        setIsVisibleThirdForm(true);
    }

    function backSecondForm() {
        setShowTakePhotoButton(false);
        setIsVisibleFirstForm(false);
        setIsVisibleThirdForm(false);
        setIsVisibleSecondForm(true);
    }

    const handleCaptchaChange = (value) => {
        setIsCaptchaValid(!!value);
    };

    const checkDenuncianteDesembarcou = () => {
        if (denuncianteDesembarcou) {
            setDenuncianteDesembarcou(false)
        } else {
            setDenuncianteDesembarcou(true)
        }
    }

    const checkSuspeitoDesembarcou = () => {
        if (suspeitoDesembarcou) {
            setSuspeitoDesembarcou(false)
        } else {
            setSuspeitoDesembarcou(true)
        }
    }

    const checkDenuncianteEmbarcou = () => {
        if (denuncianteEmbarcou) {
            setDenuncianteEmbarcou(false)
        } else {
            setDenuncianteEmbarcou(true)
        }
    }

    const checkSuspeitoEmbarcou = () => {
        if (suspeitoEmbarcou) {
            setSuspeitoEmbarcou(false)
        } else {
            setSuspeitoEmbarcou(true)
        }
    }

    // Envio da imagem
    const handleFiles = files => {
        const parts = files.base64.split(';base64,');
        setImagem(parts[1]);
        setSelectedFile(files.fileList[0].name);
    }

    const startCamera = async (event) => {
        event.preventDefault();
        setShowStartCameraButton(true);

        const isMobile = window.innerWidth <= 768;
        
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                    videoRef.current.style.display = "block";
                    videoRef.current.style.marginLeft = isMobile ? "0.2%" : "10%";
                    setShowTakePhotoButton(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const closePhoto = async (event) => {
        event.preventDefault();
        videoRef.current.style.display = "none";
        setShowTakePhotoButton(false);
        setShowStartCameraButton(false);
        stopCamera();
    };

    const stopCamera = () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            track.stop();
        });
    };

    const takePhoto = async (event) => {
        event.preventDefault();

        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const imageBase64 = canvas.toDataURL('image/png');
        const base64 = imageBase64.substring("data:image/png;base64,".length);

        videoRef.current.style.display = "none";

        setImagem(base64);

        setShowTakePhotoButton(false);
        setShowStartCameraButton(false);
        setSelectedFile("Imagem da Câmera");
        stopCamera();
    };

    const limparImagem = () => {
        setImagem("");
        setSelectedFile("");
        setShowStartCameraButton(false);
    };

    useEffect(() => {
        listagemLinhaOnibusSentido();
    }, [linhaOnibus]);

    async function listagemLinhaOnibus(value) {
        const response = await apiHml.get('/linha-onibus/cidade/' + selectValueCidade.id);
        setLinhaOnibusId(response.data.data);
        setLinhaOnibus(value);
    }

    async function listagemLinhaOnibusSentido(value) {
        const response = await apiHml.get('/linha-onibus-sentidos/linha-onibus/' + linhaOnibus);
        setLinhaOnibusSentidoId(response.data.data);
        setSentidoOnibus(value);
    }

    return (
        <div>
            <h2 id="bodyTeste">Denúncia de Assédio</h2>
            <form id="formTeste" className="w50 p-5">
                    {isVisibleFirstForm ?
                        <div id="form1Teste">
                            {loading ? (<Loadings></Loadings>) : (
                                <div>
                                    <br></br>
                                    <label className="labelTeste col-sm-2 control-label">Cidade</label>
                                    <select className="selectTeste" name="cidade" id="cidade" onChange={handleCampoChange}>
                                        <option value="0">Selecione uma Cidade</option>
                                        {cidades.map((item, index) => (
                                            <option key={item.id} value={JSON.stringify(item)}>{item.nome}</option>
                                        ))}
                                    </select>
                                    <br></br>
                                    <button type="button" id="botao-avancar" className="btnTeste btn-success" onClick={validarCampo}>Avançar</button>
                                    {erro && <p id="erro_campos_teste">Preencha o campo Cidade!</p>} {/* Exibe a mensagem de erro */}
                                </div>
                            )}
                        </div>
                        : ""
                    }
                    {isVisibleSecondForm ?
                        <div>
                            <br></br>
                            <label className="labelTeste col-sm-2 control-label">Nome<span className="text-danger">*</span></label>
                            <input type="text" className="inputTeste form-control mb-3" placeholder="Digite seu Nome"
                                value={nome} onChange={(e) => setNome(e.target.value)} />
                            <label className="labelTeste col-sm-2 control-label">Celular<span className="text-danger">*</span></label>
                            <InputMask className="inputTeste"
                                mask="(99) 99999-9999"
                                value={celular}
                                onChange={handleTelefoneChange}
                            />
                            <label className="labelTeste" htmlFor="cpf">CPF:<span className="text-danger">*</span></label>
                            <InputMask id="cpf" className="inputTeste" value={cpf} mask="999.999.999-99" onChange={handleCpfChange} />
                            {erroFormCPF && <p id="erro_email_teste">CPF Inválido!</p>}
                            <label className="labelTeste col-sm-2 control-label">E-mail<span className="text-danger">*</span></label>
                            <input type="email" className="inputTeste form-control mb-3" placeholder="Digite seu E-mail" value={email} onChange={handleChangeEmail} onBlur={validarEmail} />
                            {erroFormEmail && <p id="erro_email_teste">Formato de E-mail invalido!</p>}
                            <br></br>
                            {erroFormSecond && <p id="erro_campos_teste">Preencha todos os Campos!</p>}
                            <br></br>
                            <button type="button" className="btnTeste btn-success" onClick={validarCamposSecond}>Avançar</button>
                            {permitirLocalizacao && <p id="erro_campos_teste">Verifique e autorize a sua localização!</p>}
                        </div>
                        : ""
                    }
                    {isVisibleThirdForm ?
                        <div>
                            <br></br>
                            {!exibirApenasMensagem && !exibirMensagemManterTela && <label className="labelTeste col-sm-2 control-label">Descrição da Denúncia<span className="text-danger">*</span></label>}
                            {!exibirApenasMensagem && !exibirMensagemManterTela && <textarea id="textareaTeste" className="form-control" placeholder="Descrição da Denúncia"
                                value={descricaoDenuncia} onChange={(e) => setDescricaoDenuncia(e.target.value)}></textarea>}

                            {!exibirApenasMensagem && !exibirMensagemManterTela && <label className="labelTeste col-sm-2 control-label">Descrição do Suspeito<span className="text-danger">*</span></label>}
                            {!exibirApenasMensagem && !exibirMensagemManterTela && <textarea id="textareaTeste" className="form-control" placeholder="Descrição do Suspeito"
                                value={descricaoSuspeito} onChange={(e) => setDescricaoSuspeito(e.target.value)}></textarea>}
                            {!exibirApenasMensagem && !exibirMensagemManterTela && <label className="labelTeste col-sm-2 control-label">Local do Assédio<span className="text-danger">*</span></label>}
                            {!exibirApenasMensagem && !exibirMensagemManterTela && <select className="selectTeste" value={selectValue} onChange={handleSelectChangeLocal}>
                                {list.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                                {/* {selectValue != 0 ? handleCampoChangeLocal() : "" }        */}
                            </select>}
                        </div>
                        : ""
                    }
                    {
                        isVisibleThirdForm && selectValue == 1 ?
                            <div>
                                {!exibirApenasMensagem && <label className="labelTeste col-sm-2 control-label">Linha de Ônibus</label>}
                                {!exibirApenasMensagem && <select className="selectTeste" name="linha_onibus_id" id="linha_onibus_id" onChange={(event) => listagemLinhaOnibus(event.target.value)}>
                                    <option value="0">Selecione um Tipo</option>
                                    {linhaOnibusId.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                    ))}
                                </select>}
                                {!exibirApenasMensagem && <label className="labelTeste col-sm-2 control-label">Sentido Linha do Ônibus</label>}
                                {!exibirApenasMensagem && <select className="selectTeste" name="linha_onibus_sentido_id" id="linha_onibus_sentido_id" onChange={(event) => listagemLinhaOnibusSentido(event.target.value)}>
                                    <option value="0">Selecione um Tipo</option>
                                    {linhaOnibusSentidoId.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                    ))}
                                </select>}
                                {!exibirApenasMensagem && !exibirMensagemManterTela && <label className="labelTeste">Imagem: </label>}
                                {!exibirApenasMensagem && !exibirMensagemManterTela &&
                                    <ReactFileReader fileTypes={[".png", ".jpeg", ".jpg"]} base64={true} handleFiles={handleFiles}>
                                        <button type="button" id="btn-upload"><FontAwesomeIcon icon={faCloudArrowUp} /> Upload</button>
                                    </ReactFileReader>}
                                <div>
                                    {!exibirApenasMensagem && !exibirMensagemManterTela && <video style={{ display: "none" }} ref={videoRef} autoPlay />}
                                    <br></br>
                                    {!showStartCameraButton && !exibirApenasMensagem && !exibirMensagemManterTela && (
                                        <button id="btn-abrir-camera" onClick={startCamera}><FontAwesomeIcon icon={faCamera} /> Abrir câmera</button>
                                    )}
                                </div>
                                {showTakePhotoButton && !exibirMensagemManterTela && (
                                    <div>
                                        {!exibirApenasMensagem && !exibirMensagemManterTela && <button className="btn-fechar-camera" onClick={closePhoto}><FontAwesomeIcon icon={faCamera} /> Fechar Câmera</button>}
                                        {!exibirApenasMensagem && !exibirMensagemManterTela && <button className="btn-fechar-camera" onClick={takePhoto}>Tirar foto</button>}
                                    </div>
                                )}
                                <canvas ref={canvasRef} style={{ display: "none" }} />
                                {selectedFile && !exibirApenasMensagem && !exibirMensagemManterTela && <p id="upload-teste">Nome do arquivo: {selectedFile}</p>}
                                {imagem && !exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className='btn-danger-teste' onClick={limparImagem}>Remover Imagem</button>}
                                <br></br>
                                {erroFormThird && <p className="text-danger">Preencha todos os Campos!</p>}
                                <br></br>
                                {mostrarRecaptcha && (
                                    <ReCAPTCHA id="btn-recaptcha-teste" sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} />
                                )}
                                {/* <ReCAPTCHA sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} /> */}
                                <br></br>
                                <div style={{ display: 'flex' }}>
                                    {!exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className="btnVoltar btn-success" onClick={backSecondForm}>Voltar</button>}
                                    {!exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className="btnEnviar btn-success" onClick={validarCamposThird}>Enviar</button>}
                                </div>
                                {sucessoEnvio && <p id="msg-complementar-linha" className="text-success-teste">Denúncia enviada com sucesso! Mantenha esta janela aberta para rastrearmos sua localização. <br></br>Nos informe uma dessas opções para que pare o envio da localização.</p>}
                                {exibirMensagemManterTela && <p className="text-success-teste">Denúncia enviada com sucesso!</p>}
                            </div>
                            : ""
                    }
                    {
                        isVisibleThirdForm && selectValue == 2 ?
                            <div>
                                {!exibirApenasMensagem && !exibirMensagemManterTela && <label className="labelTeste">Imagem: </label>}
                                {!exibirApenasMensagem && !exibirMensagemManterTela &&
                                    <ReactFileReader fileTypes={[".png", ".jpeg", ".jpg"]} base64={true} handleFiles={handleFiles}>
                                        <button type="button" id="btn-upload"><FontAwesomeIcon icon={faCloudArrowUp} /> Upload</button>
                                    </ReactFileReader>}
                                <div>
                                    {!exibirApenasMensagem && !exibirMensagemManterTela && <video style={{ display: "none" }} ref={videoRef} autoPlay />}
                                    <br></br>
                                    {!showStartCameraButton && !exibirApenasMensagem && !exibirMensagemManterTela && (
                                        <button id="btn-abrir-camera" onClick={startCamera}><FontAwesomeIcon icon={faCamera} /> Abrir câmera</button>
                                    )}
                                </div>
                                {showTakePhotoButton && !exibirMensagemManterTela && (
                                    <div className="btn-fechar-camera">
                                        {!exibirApenasMensagem && !exibirMensagemManterTela && <button onClick={closePhoto}><FontAwesomeIcon icon={faCamera} /> Fechar Câmera</button>}
                                        {!exibirApenasMensagem && !exibirMensagemManterTela && <button onClick={takePhoto}>Tirar foto</button>}
                                    </div>
                                )}
                                <canvas ref={canvasRef} style={{ display: "none" }} />
                                {selectedFile && !exibirApenasMensagem && !exibirMensagemManterTela && <p id="upload-teste">Nome do arquivo: {selectedFile}</p>}
                                {imagem && !exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className='btn-danger-teste' onClick={limparImagem}>Remover Imagem</button>}
                                <br></br>
                                {mostrarRecaptcha && (
                                    <ReCAPTCHA id="btn-recaptcha-teste" sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} />
                                )}
                                {/* <ReCAPTCHA sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} /> */}
                                <br></br>
                                {!exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className="btnVoltar btn-success" onClick={backSecondForm}>Voltar</button>}
                                {!exibirApenasMensagem && !exibirMensagemManterTela && <button type="button" className="btnEnviar btn-success" id="enviar_form" onClick={validarCamposThird}>Enviar</button>}
                                {sucessoEnvio && <p className="text-success-teste">Denúncia enviada com sucesso! Mantenha esta janela aberta para rastrearmos sua localização</p>}
                                {exibirMensagemManterTela && <p className="text-success-teste">Denúncia enviada com sucesso!</p>}
                            </div>
                            : ""
                    }
                    {
                        isVisibleThirdForm && selectValue != 1 && selectValue != 2 ?
                            <div>
                                {!exibirApenasMensagem && <label className="labelTeste">Imagem: </label>}
                                {!exibirApenasMensagem &&
                                    <ReactFileReader fileTypes={[".png", ".jpeg", ".jpg"]} base64={true} handleFiles={handleFiles}>
                                        <button type="button" id="btn-upload"><FontAwesomeIcon icon={faCloudArrowUp} /> Upload</button>
                                    </ReactFileReader>}
                                <div>
                                    {!exibirApenasMensagem && <video style={{ display: "none" }} ref={videoRef} autoPlay />}
                                    <br></br>
                                    {!showStartCameraButton && !exibirApenasMensagem && (
                                        <button id="btn-abrir-camera" onClick={startCamera}><FontAwesomeIcon icon={faCamera} /> Abrir câmera</button>
                                    )}
                                </div>
                                {showTakePhotoButton && (
                                    <div className="btn-fechar-camera">
                                        {!exibirApenasMensagem && <button onClick={closePhoto}><FontAwesomeIcon icon={faCamera} /> Fechar Câmera</button>}
                                        {!exibirApenasMensagem && <button onClick={takePhoto}>Tirar foto</button>}
                                    </div>
                                )}
                                <canvas ref={canvasRef} style={{ display: "none" }} />
                                {selectedFile && !exibirApenasMensagem && <p id="upload-teste">Nome do arquivo: {selectedFile}</p>}
                                {imagem && !exibirApenasMensagem && <button type="button" className='btn-danger-teste' onClick={limparImagem}>Remover Imagem</button>}
                                <br></br>
                                {erroFormThird && <p className="text-danger">Preencha todos os Campos!</p>}
                                <br></br>
                                {mostrarRecaptcha && (
                                    <ReCAPTCHA id="btn-recaptcha-teste" sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} />
                                )}
                                {/* <ReCAPTCHA sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} /> */}
                                <br></br>
                                <div>
                                    {!exibirApenasMensagem && <button type="button" className="btnVoltar btn-success" onClick={backSecondForm}>Voltar</button>}
                                    {!exibirApenasMensagem && <button type="button" className="btnEnviar btn-success" id="enviar_form" onClick={validarCamposThirdOutros}>Enviar</button>}
                                    {sucessoEnvio && <p id="msg-outros" className="text-success-teste">Denúncia enviada com sucesso!</p>}
                                </div>
                            </div>
                            : ""
                    }
                    {isVisibleFourthForm && selectValue == 1 ?
                        <div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Denunciante Desembarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkDenuncianteDesembarcou} sim={denuncianteDesembarcou} ></Checkbox>}
                                </div>
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Suspeito Desembarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkSuspeitoDesembarcou} sim={suspeitoDesembarcou} ></Checkbox>}
                                </div>
                            </div>
                            {!isLoading && <button type="button" className="btnTeste btn-success" id="enviar_form" onClick={atualizarDenuncia}>Enviar</button>}
                            {isLoading && <Loadings></Loadings>}
                        </div>
                        : ""
                    }
                    {sucessoEnvioFinal && <p id="msg-outros" className="text-success-teste">Localização finalizada. Denúncia enviada com sucesso!</p>}

                    {isVisibleFourthForm && selectValue == 2 && formFinish == false ?
                        <div>
                            <div>
                                {msgEnvioOcorrencia && <p id="msg-tipo-2" className="text-success-teste">Denúncia enviada com sucesso! Nos informe dados complementares e se o Denunciante e o Suspeito embarcou para que possamos acompanhar sua localização em tempo real.</p>}
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Denunciante Embarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkDenuncianteEmbarcou} sim={denuncianteEmbarcou} ></Checkbox>}
                                </div>
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Suspeito Embarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkSuspeitoEmbarcou} sim={suspeitoEmbarcou} ></Checkbox>}
                                </div>
                            </div>
                            {!isLoading && <button type="button" className="btnTeste btn-success" id="enviar_form" onClick={atualizarDenuncia}>Enviar</button>}
                            {isLoading && <Loadings></Loadings>}
                            <div>
                                {sucessoEnvio && suspeitoEmbarcou == true && denuncianteEmbarcou == true && <p className="text-success-teste">Denúncia enviada com sucesso! Mantenha esta janela aberta para rastrearmos sua localização</p>}
                            </div>
                        </div>
                        : ""
                    }

                    {isVisibleFifthForm && suspeitoEmbarcou == true && denuncianteEmbarcou == false || isVisibleFifthForm == true && denuncianteEmbarcou == true && suspeitoEmbarcou == true ?
                        <div>
                            <div>
                                {msgEnvioOcorrencia && <p id="msg-complementar" className="text-success-teste">Denúncia enviada com sucesso! Nos informe dados complementares para obtermos mais informações.</p>}
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Linha de Ônibus</label>}
                            </div>
                            <div>
                                {!isLoading && <select className="selectTeste" name="linha_onibus_id" id="linha_onibus_id" onChange={(event) => listagemLinhaOnibus(event.target.value)}>
                                    <option value="0">Selecione um Tipo</option>
                                    {linhaOnibusId.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                    ))}
                                </select>}
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Sentido Linha do Ônibus</label>}
                            </div>
                            <div>
                                {!isLoading && <select className="selectTeste" name="linha_onibus_sentido_id" id="linha_onibus_sentido_id" onChange={(event) => listagemLinhaOnibusSentido(event.target.value)}>
                                    <option value="0">Selecione um Tipo</option>
                                    {linhaOnibusSentidoId.map((item, index) => (
                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                    ))}
                                </select>}
                            </div>
                            <br />
                            {!isLoading && <button type="button" className="btnTeste btn-success" id="enviar_form" onClick={atualizarDenunciaFifth}>Enviar</button>}
                            {isLoading && <Loadings></Loadings>}
                        </div>
                        : ""
                    }
                    {isVisibleSixthForm && suspeitoEmbarcou == true && denuncianteEmbarcou == true && formFinish == true ?
                        <div>
                            <div>
                                {sucessoEnvioFinish && !isLoading && <p id="msg-complementar" className="text-success-teste">Denúncia enviada com sucesso! Mantenha esta janela aberta para rastrearmos sua localização. <br></br>Nos informe uma dessas opções para que pare o envio da localização.</p>}
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Denunciante Desembarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkDenuncianteDesembarcou} sim={denuncianteDesembarcou} ></Checkbox>}
                                </div>
                            </div>
                            <div>
                                {!isLoading && <label className="labelTeste col-sm-2 control-label">Suspeito Desembarcou?</label>}
                                <div className="checkboxTeste">
                                    {!isLoading && <Checkbox onChange={checkSuspeitoDesembarcou} sim={suspeitoDesembarcou} ></Checkbox>}
                                </div>
                            </div>
                            {!isLoading && <button type="button" className="btnTeste btn-success" id="enviar_form" onClick={atualizarDenunciaFifth}>Enviar</button>}
                        </div>
                        : ""
                    }
                    {exibirMensagemManterTela && <p id="msg-denuncia" className="text-success-teste">Denúncia enviada com sucesso!</p>}
            </form>
        </div>
    )
}


export default Teste;