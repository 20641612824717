import api from "../services/api";
import { useState, useEffect } from "react";

import "./Home.css";

const Confirmacao = () => {
    const [codeValidate, setCodeValidate] = useState("");
    const [exibirApenasMensagem, setMensagemConfirmacao] = useState(false);
    useEffect(() => {
      if (window.location.search != "") {
        setCodeValidate(window.location.search.split("=")[1]);
      }
    }, [])


    async function sendData(event){
            try{
                setMensagemConfirmacao(true);
                const response = await api.get('/denuncias-assedios/'+codeValidate);
                console.log(response)
                let id = response.data.data.id; 
                if(response.data.data.id){
                    const result = await api.put("/denuncias-assedios", {
                        id: id,
                        status: "C"
                    });
                }else{

                }
                return alert ("Denúncia confirmada com sucesso");

            }catch(err){
                console.log(err.message);
                setMensagemConfirmacao(false);
                alert ("Id da Denúncia não encontrado");
            } finally{
                console.log("finalizado")
            }
            return console.log("enviando dados")
    };

  return (

        <form>
            <div>
            {!exibirApenasMensagem && <label>Confirmação de Denúncia: </label>}
            </div>
            {!exibirApenasMensagem && <button type="button" className="confirmaDenuncia" onClick={sendData}>Confirmar</button>}
            <div>
            {exibirApenasMensagem && <label id="lbl-denuncia">Denúncia confirmada com sucesso!</label>}
            </div>
        </form>

  )
}

export default Confirmacao