export const Url = {    
   
    // urlhml : "http://153cidadao.local/api/v1",
    urlhml :  "https://hmlapi.153cidadao.com.br/api/v1",
    url :  "https://api.153cidadao.com.br/api/v1",
};

export const googleMapsApi = {
    android: {
        apiKey: "AIzaSyCRFZN6drTiPFzz2N7HilP9TgYxNZQidqQ"
    },
    ios: {
        apiKey: "AIzaSyBft2z2KNOr72oPM4SUnRHyLSH_d_HrOek"
    }
};