import React, { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

const Loadings = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoading(prevLoading => !prevLoading);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
      <BeatLoader color="#36D7B7" size={25} loading={loading} />
    </div>
  );
};

export default Loadings;
