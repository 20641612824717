
import React from "react";
import Switch from "react-switch";

const Checkbox = ({ onChange, sim}) => {
    return (
      <label>
        <div>
          <Switch onChange={onChange} checked={sim} />
        </div>
      </label> 
    );
};

export default Checkbox;