import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({label}) => {

  return (
    <nav className="navbar">
        <h2>
            <label className="labelDenuncia">{label}</label>
        </h2>
        <ul>
            <li>
                <Link to={`/`}></Link>
            </li>
        </ul>
    </nav>
  )
}

export default Navbar