import api, {apiCconet} from "../services/api";
import { useState, useEffect, useRef } from "react";
import React from 'react';
import Checkbox from "../components/Form/Checkbox";
import ReactFileReader from 'react-file-reader';
import InputMask from "react-input-mask";
import * as EmailValidator from "email-validator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { googleMapsApi } from "../Config/config";
import axios from "axios";
import Loadings from "../components/Form/Loadings";
import ReCAPTCHA from "react-google-recaptcha";
import { keyRecaptcha } from "../Config/keyReCaptcha";

import "./Denuncia.css";

const Denuncia = () => {
    // Inputs 
    const [nome, setNome] = useState([]);
    const [celular, setCelular] = useState([]);
    const [email, setEmail] = useState([]);
    const [cpf, setCpf] = useState([]);
    const [descricaoDenuncia, setDescricaoDenuncia] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [localizacaoAtual, setLocalizacaoAtual] = useState(false);
    const [exibirApenasMensagem, setMensagemConfirmacao] = useState(false);
    const [tipoOcorrencia, setTipoOcorrencia] = useState([]);
    const [tipoOcorrenciaId, setTipoOcorrenciaId] = useState([]);
    const [bairro, setBairro] = useState([]);
    const [numero, setNumero] = useState([]);
    const [endereco, setEndereco] = useState([]);
    //Upload de imagem e foto
    const [imagem, setImagem] = useState(null);
    const videoRef = useRef();
    const canvasRef = useRef();
    const [showTakePhotoButton, setShowTakePhotoButton] = useState(false);
    const [showStartCameraButton, setShowStartCameraButton] = useState(false);
    //Validação de forms
    const [isVisibleFirstForm, setIsVisibleFirstForm] = useState(true);
    const [isVisibleSecondForm, setIsVisibleSecondForm] = useState(false);
    const [isVisibleThirdForm, setIsVisibleThirdForm] = useState(false);
    const [isVisibleCamposEndereco, setIsVisibleCamposEndereco] = useState(false);
    //Selects  
    const [selectedFile, setSelectedFile] = useState("");
    const [selectValueCidade, setSelectValueCidade] = useState(0);
    const [selectValueCidadeViaUrl, setSelectValueCidadeViaUrl] = useState(0);
    const [validaSelecCidade, setValidaSelecCidade] = useState(false);

    const list = [
        {id: 0, name: 'Selecione um Local'},
        {id: 1, name: 'Linha de Ônibus'},
        {id: 2, name: 'Ponto de Ônibus'},
        {id: 3, name: 'Escola'},
        {id: 4, name: 'Estabelicimento Público'},
        {id: 5, name: 'Estabelicimento Privado'},
    ];
    //Validações de erros
    const [erro, setErro] = useState(false);
    const [erroFormSecond, setErroFormSecond] = useState(false);
    const [erroFormThird, setErroFormThird] = useState(false);
    const [erroFormEmail, setErroFormEmail] = useState(false);
    const [erroFormCPF, setErroFormCpf] = useState(false);
    //location e mensagem de envio
    const [location, setLocation] = useState(null);
    const [sucessoEnvio, setMensagemSucessoEnvio] = useState(false);
    const canvas = document.createElement('canvas');
    const [loading, setLoading] = useState(true);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    async function sendData(){
        if(nome && celular){
            try{
                
                const celularSemMascara = removerMascaraCelular(celular);
                const dadosEndereco = await latlon(endereco, numero);

                //REVERSEGEOLOCATION PARA REVERTER A LAT E LNG
                // const dadosLatLng = await reverseGeocode(location.latitude, location.longitude);

                if(endereco.length != 0 && numero.length != 0 && bairro.length != 0){
                    const latitude = dadosEndereco.geometry.location.lat;
                    const longitude = dadosEndereco.geometry.location.lng;
                  
                    if (dadosEndereco) {
                        const resultDenuncia = await api.post("/denuncias",{
                            tipo_denuncia_id: tipoOcorrenciaId,
                            is_denuncia:0,
                            is_sigilo:0,
                            observacao: descricaoDenuncia,
                            endereco_ponto_referencia: "N/A",
                            cidade_id: selectValueCidade.id,
                            solicitante_nome: nome,
                            solicitante_fone: celular,
                            solicitante_endereco_complemento: endereco,
                            solicitante_endereco_numero: numero,
                            endereco_numero: numero,
                            solicitante_endereco_bairro: bairro,
                            solicitante_posicao_latitude:  latitude.toFixed(5),
                            solicitante_posicao_longitude: longitude.toFixed(5),
                            endereco_latitude: latitude,
                            endereco_longitude: longitude,
                            imagemBase64: imagem
                        },{
                            headers: {
                            'Content-Type': 'application/json',
                            'auth-token': celularSemMascara
                            }
                        });
                    }
                }else{
                    const resultDenuncia = await api.post("/denuncias",{
                        tipo_denuncia_id: tipoOcorrenciaId,
                        is_denuncia:0,
                        is_sigilo:0,
                        observacao: descricaoDenuncia,
                        endereco_ponto_referencia: "N/A",
                        cidade_id: selectValueCidade.id,
                        solicitante_nome: nome,
                        solicitante_fone: celular,
                        solicitante_endereco_complemento: "Formulário Denúncia",
                        solicitante_endereco_numero: "Formulário Denúncia",
                        endereco_numero: "N/A",
                        solicitante_endereco_bairro: "Formulário Denúncia",
                        solicitante_posicao_latitude:  location.latitude,
                        solicitante_posicao_longitude: location.longitude,
                        endereco_latitude:  location.latitude,
                        endereco_longitude: location.longitude
                    },{
                        headers: {
                        'Content-Type': 'application/json',
                        'auth-token': celularSemMascara
                        }
                    });
                }
       
            }catch(err){
                console.log(err.message)
            } finally{
                console.log("finalizado")
            }
            return console.log("enviando dados")
        }
        return console.log("enviando dados")
    };

    async function sendDataUsuario(){
        if(nome && celular || nome && email){
            try{
                const resultUsuario = await api.post("/usuarios-create",{
                        nome: nome,
                        telefone: celular,
                        email: email,
                        cpf: cpf,
                        uf_usuario: selectValueCidade.uf,
                        cidade_usuario: selectValueCidade.nome,
                        endereco: "Formulário Denúncia",
                        numero: "Formulário Denúncia",
                        bairro: "Formulário Denúncia",
                        tipo_pessoa: "F",
                        cidade_id: selectValueCidade.id,
                        profissao: "Formulário Denúncia",
                });

            }catch(err){
                console.log(err.message)
            } finally{
                console.log("finalizado")
            }
            return console.log("enviando dados")
        }
        return console.log("enviando dados")
    };

    // Função para obter a localização atual do usuário
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                // Extrai as coordenadas de latitude e longitude da posição obtida
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
            },
            (error) => {
                console.error("Erro ao obter localização: ", error);
            }
            );
        } else {
            console.error("Geolocalização não suportada pelo navegador.");
        }
    };

    async function latlon(endereco, nr_endereco) {
        endereco = nr_endereco + "+" + endereco;
        endereco = endereco + "+" + selectValueCidade.nome + "+" + selectValueCidade.uf;
        endereco = endereco.trim();
        endereco = endereco.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        endereco = encodeURIComponent(endereco);
      
        try {
          let key = googleMapsApi.android.apiKey;
          let url = "https://maps.google.com/maps/api/geocode/json?address=" + endereco + "&key=";
      
          let response = await axios.get(url + key);
          if (response && response.status === 200 && response.data && response.data.results.length > 0) {
            return response.data.results[0]; // Retorna o primeiro resultado encontrado
          }
          return null;
      
        } catch (error) {
          throw "Falha ao realizar o geocode reverso";
        }
    }

    // async function reverseGeocode(latitude, longitude) {
    //     const key = googleMapsApi.android.apiKey;
    //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`;
    //     try {
    //       const response = await axios.get(url);
    //       const address = response.data.results[0].formatted_address;
    //       return address;
    //     } catch (error) {
    //       throw new Error("Erro ao realizar o geocode reverso");
    //     }
    //   }
    async function listagemTipoOcorrencia(value) {
        const celularSemMascara = removerMascaraCelular(celular)
        const headers = {
          'Content-Type': 'application/json',
          'auth-token': celularSemMascara
        }
        const response = await api.get('/cidade/' + selectValueCidade.id + '/tipos-denuncia', { headers })
        setTipoOcorrencia(response.data.data.tiposDenunciaSv)
        setTipoOcorrenciaId(value)
    }

    async function buscaUsuario() {
        const celualrSemMascara = removerMascaraCelular(celular)
        const cpfSemMascara = removerMascaraCelular(cpf)
        try{
            const response = await api.get("/usuarios/telefone/"+celualrSemMascara);
            if(response.data.data){
                listagemTipoOcorrencia();
            }
        }catch (error){
            try{
                const responseCpf = await api.post("/usuarios/cpf",{
                    cpf: cpfSemMascara
                });
    
                if(responseCpf.data.data){
                    if(responseCpf.data.data.telefone != celular){
                        const resultUsuario = await api.put("/usuarios-update",{
                            id: responseCpf.data.data.id,
                            nome: responseCpf.data.data.nome,
                            cpf: responseCpf.data.data.cpf,
                            telefone: celular,
                            email: responseCpf.data.data.nome,
                            uf_usuario: responseCpf.data.data.uf_usuario,
                            cidade_usuario: responseCpf.data.data.cidade_usuario,
                            endereco: responseCpf.data.data.endereco,
                            numero: responseCpf.data.data.numero,
                            bairro: responseCpf.data.data.bairro,
                            tipo_pessoa: responseCpf.data.data.tipo_pessoa,
                            cidade_id: responseCpf.data.data.cidade_id,
                            profissao: responseCpf.data.data.profissao
                        });
                    }
                    listagemTipoOcorrencia();
                }
            }catch(error){
                sendDataUsuario();
                await listagemTipoOcorrencia();
            }
        }
    }

    function removerMascaraCelular(celular) {
        return celular.replace(/\D/g, '');
    }

    useEffect(() => {
        async function lista(){
          await listagemGms();
        }
        lista();
    }, []);
    
    async function listagemGms() {
        const response = await api.get('/cidade/formulario-denuncia')
        setCidades(response.data.data.cidades);
    }

    useEffect(() => {
        async function handleUrlCode() {
          if (selectValueCidade !== 0 && validaSelecCidade) {
            nextSecondForm();
            return true;
          }
      
          if (window.location.search !== "") {
            const cidadeId = window.location.search.split("=")[1];
            setSelectValueCidadeViaUrl(cidadeId);
            const cidadeItem = cidades.find(item => item.id === parseInt(cidadeId));
      
            if (cidadeItem) {
              setSelectValueCidade(cidadeItem);
              setValidaSelecCidade(true);
            } else {
              setValidaSelecCidade(false);
            }
          }
        }
        handleUrlCode();
        setLoading(false);
      }, [cidades, selectValueCidade, validaSelecCidade]);
      
      useEffect(() => {
        if (selectValueCidadeViaUrl > 0) {
          const botaoAvancar = document.getElementById('botao-avancar');
          if (botaoAvancar && validaSelecCidade) {
            setValidaSelecCidade(true);
            botaoAvancar.click();
          }
        }
      }, [selectValueCidadeViaUrl, validaSelecCidade]);
      
      const handleCampoChange = (event) => {
        const item = JSON.parse(event.target.value);
        
        if (item) {
          setSelectValueCidade(item);
        } else {
          setValidaSelecCidade(false); 
        }
      };

    // Realiza a validação do campo
    const validarCampo = (event) => {
        event.preventDefault();
        if (selectValueCidade != 0) {
            nextSecondForm();
        } else {
            setErro(true); 
            return;
        }
    };
    
    // Realiza a validação do campo
    const validarCamposSecond = (event) => {
        event.preventDefault();
        if(erroFormCPF == true){
            setErroFormCpf(true)
            return;
        }
        setErroFormSecond(false); 
        setErroFormEmail(false); 
        if (nome.length > 0 && celular.length > 0 && cpf.length > 0 && erroFormEmail == false && email.length > 0) {
            // sendDataUsuario();
            // sendData();
            buscaUsuario();
            // buscaCpf();
            // listagemTipoOcorrencia();
            nextThirdForm();
            setShowStartCameraButton(false);
            setErroFormSecond(false);
            setErroFormEmail(false); 
        }else if(erroFormEmail){
            alert("Formato do E-mail invalido!")
            setErroFormEmail(true); 
        }else {
            alert("Preencha os Campos Obrigatórios (*)!")
            setErroFormSecond(true); 
            return;
        }
    };

    // Realiza a validação do campo
    const validarCamposThirdOutros = (event) => {
        event.preventDefault();
        if (tipoOcorrenciaId == null || ! tipoOcorrenciaId.length){
            alert("O campo tipo de denúncia é obrigatório");
            return false;
        }
        if (!isCaptchaValid) {
            alert('Por favor, verifique o CAPTCHA antes de enviar o formulário.');
            return;
        }

        setErroFormThird(false); 
        setMensagemSucessoEnvio(false);
        if (descricaoDenuncia.length > 0 && localizacaoAtual == true || descricaoDenuncia.length > 0 && endereco.length > 0 && numero.length > 0 && bairro.length > 0) {
            sendData();
            setErroFormThird(false); 
            setMensagemSucessoEnvio(true);
            setMensagemConfirmacao(true);
        } else {
            alert("Preencha todos os Campos!")
            setErroFormThird(true); 
            return;
        }
    };

    // Mask para celular
    const handleTelefoneChange = (event) => {
        setCelular(event.target.value);        
    };

    // Inicio validações CPF
    useEffect(() => {
        if (cpf.length === 11) {
          // Executa a validação do CPF quando o usuário terminar de digitar
          if (validarCPF(cpf)) {
            setErroFormCpf(false);
          } else {
            setErroFormCpf(true);
          }
        }
      }, [cpf]);

    function validarCPF(cpf) {
        // Remove caracteres especiais e de formatação
        cpf = cpf.replace(/\D/g, '');
      
        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) {
          return false;
        }
      
        // Verifica se todos os dígitos são iguais
        if (/^(\d)\1{10}$/.test(cpf)) {
          return false;
        }
      
        // Calcula o primeiro dígito verificador
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let digit = 11 - (sum % 11);
        if (digit >= 10) {
          digit = 0;
        }
        if (parseInt(cpf.charAt(9)) !== digit) {
          return false;
        }
      
        // Calcula o segundo dígito verificador
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        digit = 11 - (sum % 11);
        if (digit >= 10) {
          digit = 0;
        }
        if (parseInt(cpf.charAt(10)) !== digit) {
          return false;
        }

        return true;
    }

    function handleCpfChange(event) {
        const value = event.target.value;
        const numericValue = value.replace(/\D/g, ''); // Remove os caracteres não numéricos
        setCpf(numericValue);
    }
    // fim alidações CPF

    // Mask para Email
    const handleChangeEmail = (evento) => {
        setEmail(evento.target.value);
    };

    const validarEmail = () => {
        if (EmailValidator.validate(email)) {
            // Se o e-mail for válido
            setErroFormEmail(false); 
        } else {
            setErroFormEmail(true); 
        }
    };

    const handleCaptchaChange = (value) => {
        setIsCaptchaValid(!!value);
      };

    function nextSecondForm() {
        setIsVisibleFirstForm(false);
        setIsVisibleThirdForm(false);
        setErroFormCpf(false);
        setIsVisibleSecondForm(true);
    }

    function nextThirdForm() {
        setIsVisibleFirstForm(false);
        setIsVisibleSecondForm(false);
        setIsVisibleThirdForm(true);
    }

    function backSecondForm() {
        setShowTakePhotoButton(false);
        setIsVisibleFirstForm(false);
        setIsVisibleThirdForm(false);
        setIsVisibleSecondForm(true);
    }

    function escondeCampos() {
        setIsVisibleCamposEndereco(true);
    }

    function mostraCampos() {
        setIsVisibleCamposEndereco(false);
    }
 
    const checkLocalizacaoAtual = () =>{
        if(localizacaoAtual) {
            setLocalizacaoAtual(false)
            mostraCampos();
        }else{
            setLocalizacaoAtual(true)
            getLocation();
            escondeCampos();
            setEndereco("");
            setNumero("");
            setBairro("");
        }
    }

    // Envio da imagem
    const handleFiles = files => {
        const parts = files.base64.split(';base64,');
        setImagem(parts[1]);
     
        setSelectedFile(files.fileList[0].name);
    }

    const startCamera = async (event) => {
        event.preventDefault();
        setShowStartCameraButton(true);
        navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            videoRef.current.style.display = "block";
            setShowTakePhotoButton(true);
          }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const closePhoto = async (event) => {
        event.preventDefault();
        videoRef.current.style.display = "none";
        setShowTakePhotoButton(false);
        setShowStartCameraButton(false);
        stopCamera();
    };

    const stopCamera = () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
    
        tracks.forEach((track) => {
          track.stop();
        });
    };

    const takePhoto = async (event) => {
        event.preventDefault();

        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height); 

        const imageBase64 = canvas.toDataURL('image/png');
        const base64 = imageBase64.substring("data:image/png;base64,".length);

        videoRef.current.style.display = "none";

        setImagem(base64);

        setShowTakePhotoButton(false);
        setShowStartCameraButton(false);
        setSelectedFile("Imagem da Câmera");
        stopCamera();
    };

    const limparImagem = () => {
        setImagem("");
        setSelectedFile("");
        setShowStartCameraButton(false);
    };

  return (
        <form id="form1" className="w50 p-5">
            <div>
                {sucessoEnvio && <p className="text-success">Denúncia enviada com sucesso!</p>}
            </div>
            {isVisibleFirstForm ?
                <div>
                    <div>
                        {loading ? ( <Loadings></Loadings>) : (
                            <div>
                                <label className="col-sm-2 control-label">Cidade</label>
                                <select name="cidade" id="cidade" onChange={handleCampoChange}>
                                    <option value="">Selecione uma Cidade</option>
                                    {cidades.map((item, index) => (
                                            <option key={item.id} value={JSON.stringify(item)}>{item.nome}</option>
                                    ))}  
                                </select>
                                <br></br>
                                <button type="button" id="botao-avancar" className="btn btn-success" onClick={validarCampo}>Avançar</button>
                                {erro && <p id="erro_campos">Preencha o campo Cidade!</p>} {/* Exibe a mensagem de erro */}
                            </div>
                        )}
                    </div>
                </div>
                : ""
            }
            { isVisibleSecondForm ? 
                <div>
                   <label className="col-sm-2 control-label">Nome<span className="text-danger">*</span></label>
                   <input type="text" className="form-control mb-3" placeholder="Digite seu Nome"
                       value={nome} onChange={(e) => setNome(e.target.value)} />
                   <label className="col-sm-2 control-label">Celular<span className="text-danger">*</span></label>
                    <InputMask
                        mask="(99) 99999-9999"
                        value={celular}
                        onChange={handleTelefoneChange}
                    />
                    <label htmlFor="cpf">CPF:<span className="text-danger">*</span></label>
                    <InputMask id="cpf" value={cpf} mask="999.999.999-99" onChange={handleCpfChange} />
                    {erroFormCPF && <p id="erro_email">CPF Inválido!</p>}
                    <label className="col-sm-2 control-label">E-mail<span className="text-danger">*</span></label>
                    <input type="email" className="form-control mb-3" placeholder="Digite seu E-mail" value={email} onChange={handleChangeEmail} onBlur={validarEmail} />
                    {erroFormEmail && <p id="erro_email">Formato de E-mail invalido!</p>}
                   <br></br>
                    {erroFormSecond && <p id="erro_campos">Preencha os Campos Obrigatórios (*)!</p>}
                   <br></br>
                   <button type="button" className="btn btn-success" onClick={validarCamposSecond}>Avançar</button>
               </div>
               : ""
            }
        {isVisibleThirdForm ? 
            <div>
               {!exibirApenasMensagem && <label className="col-sm-2 control-label">Tipo da Ocorrência<span className="text-danger">*</span></label>}
               {!exibirApenasMensagem && <select name="tipo_ocorrencia" id="tipo_ocorrencia" onChange={(event) => listagemTipoOcorrencia(event.target.value)}>
                <option value="">Selecione um Tipo</option>
                {tipoOcorrencia.length === 1 &&
                    tipoOcorrencia.map((item, index) => (
                        <option selected key={item.id} value={item.id}>{item.descricao}</option>
                ))}
                {tipoOcorrencia.length > 1 &&
                    tipoOcorrencia.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.descricao}</option>
                ))}  
                </select>}
                <br/>
                {!exibirApenasMensagem && <label className="col-sm-2 control-label">Descrição da Denúncia<span className="text-danger">*</span></label>}
                {!exibirApenasMensagem && <textarea className="form-control form input mb-3" placeholder="Descrição da Denúncia"
                    value={descricaoDenuncia} onChange={(e) => setDescricaoDenuncia(e.target.value)}></textarea>}
                <br/>
                {!exibirApenasMensagem && <label className="col-sm-2 control-label">Utilizar a localiação atual?</label>}
                <div>
                {!exibirApenasMensagem && <Checkbox onChange={checkLocalizacaoAtual} sim={localizacaoAtual} ></Checkbox>}
                </div>
                <br/>
                {!isVisibleCamposEndereco && !exibirApenasMensagem && <label className="col-sm-2 control-label">Endereço<span className="text-danger">*</span></label>}
                {!isVisibleCamposEndereco && !exibirApenasMensagem && <input type="text" className="form-control mb-3" placeholder="Digite seu Endereço"
                       value={endereco} onChange={(e) => setEndereco(e.target.value)} />}
                {!isVisibleCamposEndereco && !exibirApenasMensagem && <label className="col-sm-2 control-label">Número<span className="text-danger">*</span></label>}
                {!isVisibleCamposEndereco && !exibirApenasMensagem &&  <input type="text" className="form-control mb-3" placeholder="Digite Número"
                       value={numero} onChange={(e) => setNumero(e.target.value)} />}
                {!isVisibleCamposEndereco && !exibirApenasMensagem && <label className="col-sm-2 control-label">Bairro<span className="text-danger">*</span></label>}
                {!isVisibleCamposEndereco && !exibirApenasMensagem && <input type="text" className="form-control mb-3" placeholder="Digite o Bairro"
                       value={bairro} onChange={(e) => setBairro(e.target.value)} />}
                <br/>
                {!exibirApenasMensagem && <label>Imagem: </label>}
                {!exibirApenasMensagem  && 
                    <ReactFileReader fileTypes={[".png",".jpeg",".jpg"]} className="col-dm-3" base64={true} handleFiles={handleFiles}>
                        <button type="button" className='btn'><FontAwesomeIcon icon={faCloudArrowUp} /> Upload</button>
                    </ReactFileReader>}
                <div>
                {!exibirApenasMensagem && <video style={{ display: "none" }} ref={videoRef} autoPlay />}
                    <br></br>
                    {!showStartCameraButton && !exibirApenasMensagem && (
                        <button onClick={startCamera}><FontAwesomeIcon icon={faCamera} /> Abrir câmera</button>
                    )}
                </div>
                {showTakePhotoButton && (
                    <div>
                        {!exibirApenasMensagem && <button onClick={closePhoto}><FontAwesomeIcon icon={faCamera} /> Fechar Câmera</button>}
                        {!exibirApenasMensagem && <button onClick={takePhoto}>Tirar foto</button>}
                    </div>
                )}
                <canvas ref={canvasRef} style={{ display: "none" }} />
                {selectedFile && !exibirApenasMensagem && <p id="upload">Nome do arquivo: {selectedFile}</p>}
                {imagem && !exibirApenasMensagem && <button type="button" className='btn-danger' onClick={limparImagem}>Remover Imagem</button>}
                <br></br>
                {erroFormThird && <p className="text-danger">Preencha todos os Campos!</p>}
                <br></br>
                <ReCAPTCHA sitekey={keyRecaptcha.Key} onChange={handleCaptchaChange} />
                <br></br>
                {!exibirApenasMensagem && <button type="button" className="btn btn-success" onClick={backSecondForm}>Voltar</button>}
                {!exibirApenasMensagem && <button type="button" className="btn btn-success" id="enviar_form" onClick={validarCamposThirdOutros}>Enviar</button>}
            </div>
            : ""
        }
        </form>
  )
}

export default Denuncia;
