import Navbar from './components/Form/Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';

function App() {
  const location = useLocation();
  let label = '';
  
  if (location.pathname === '/assedio') {
    label = 'Denúncia de Assédio';
  } else if (location.pathname === '/') {
    label = 'Denúncia';
  }else if (location.pathname === '/qrcode') {
    label = 'QRCode';
  } else if (location.pathname === '/confirmacao') {
    label = 'Confirmação de Denúncia';
  }


  return (
    <div className='App'>
      <Navbar label={label}/>
      <div className='container'>
        <Outlet/>
      </div>
    </div>
  );
}
export default App;
