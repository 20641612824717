import axios from "axios";
import { Url } from "../Config/config";

const api = axios.create({
    baseURL: Url.url,
    headers:{
        "Content-Type": "application/json",
    },
});

export const apiCconet = (url = "") =>{
    return axios.create({
        baseURL: url,
        headers:{
            "Content-Type": "application/json",
        }
    });
}

export const apiHml = axios.create({
    baseURL: Url.urlhml,
    headers:{
        "Content-Type": "application/json",
    },
});

export default api;