import React from 'react';
import QRCode from 'qrcode.react';
import "./Denuncia.css";

const QRCodeComponent = () => {
  // const urlDenuncia = 'https://formulario.153cidadao.com.br/denuncia/cidade?=2';
  // const urlAssedio = 'https://formulario.153cidadao.com.br/assedio//teste/cidade?=2';
  // const urlAssedio = 'http://192.168.3.197:3000/assedio/cidade?=2';
  // const urlAssedio = 'http://192.168.3.198:3000/assedio';
  // const urlAssedio = 'http://192.168.100.74:3000/teste';
  const urlAssedio = 'https://formulario.153cidadao.com.br/teste';

  return (
    <div>
      {/* <div>
      <label > QRCode Denúncia</label>
      </div>
      <QRCode value={urlDenuncia} /> */}
      <br />
      <br />
      <div>
      <label > QRCode Denúncia Assédio</label>
      </div>
      <QRCode value={urlAssedio} />
    </div>
  );
};

export default QRCodeComponent;
